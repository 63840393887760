

















import { Component, Vue } from 'vue-property-decorator'
import { Integration } from '@tada-team/tdproto-ts'
import { integrationsStore, uiStore } from '@/store'
import IntegrationsItem from './IntegrationsItem.vue'

interface IntegrationsListItem {
  enabled: boolean;
  group: string;
  kind: string;
  uid: string;
}

@Component({
  name: 'IntegrationsTabConnected',
  components: {
    IntegrationsItem,
  },
})
export default class IntegrationsTabConnected extends Vue {
  get integrations (): Integration[] {
    return integrationsStore.state.data
  }

  get integrationsList (): IntegrationsListItem[] {
    const list: IntegrationsListItem[] = []
    this.integrations.forEach(i => {
      // should never happen, only done so because of tdproto typings
      if (!i.uid) return

      const { uid, enabled, group } = i
      const kind = integrationsStore.state.kinds.find(k => k.kind === i.kind)
      const kindName = kind?.kind ?? ''
      list.push({ enabled, group, kind: kindName, uid })
    })

    return list
  }

  edit (uid: string) {
    uiStore.actions.showModal({
      instance: 'IntegrationManagement',
      payload: { uid, kind: null },
    })
  }

  enable (uid: string, v: boolean) {
    let integration = this.integrations.find(i => i.uid === uid)
    if (!integration) return

    // deep copy and change bool field - prevent mutating object passed by ref
    integration = Integration.fromJSON(integration.toJSON())
    integration.enabled = v

    integrationsStore.actions.edit({
      integration,
      earlySave: true, // it's a toggle - save ASAP, react immediately
    })
  }
}
