




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IntegrationKind } from '@tada-team/tdproto-ts'
import { integrationsStore, groupsStore } from '@/store'

@Component({
  name: 'IntegrationsItem',
  components: {
    GroupIcon: () => import('@/components/UI/GroupIcon.vue'),
    IconPen: () => import('@/components/UI/icons/IconPen.vue'),
  },
})
export default class IntegrationsItem extends Vue {
  /**
   * Toggle state. Listen to 'toggle' event to get updates.
   */
  @Prop({
    type: Boolean,
    required: true,
  }) readonly enabled!: boolean

  /**
   * Kind name to use for icon and name.
   */
  @Prop({
    type: String,
    required: true,
  }) readonly kind!: string

  /**
   * Group id to use to display group name.
   */
  @Prop({
    type: String,
    required: true,
  }) readonly group!: string

  get computedKind (): IntegrationKind {
    return integrationsStore.getters.integrationsKindByKind[this.kind]
  }

  groupDisplayName (jid: string | null): string {
    if (!jid) return ''
    return groupsStore.getters.displayName(jid)
  }
}
